// const devurl="http://localhost/vrisharya/api";
// const devurl="https://test.vrisharyatechnologies.com/api";
// const devurl="https://1c4654cf-c554-48a7-80da-0fc642e71421.in.btunnel.co.in";
import fetchUrl from './fetchurl';

// Usage example
const devurl = await fetchUrl();
console.log('Fetched URL:', devurl);

export const finalUrl=devurl;
export const statesOfIndia = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry',
  ];