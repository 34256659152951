import React from 'react'
import { Logo } from '../assets/index';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll"

const Footer = () => {

  const navigator = useNavigate();

  const navList = [
    { id: "#", name: "Home" },
    { id: "#about", name: "About" },
    { id: "#services", name: "Service" },
    { id: "#testimonial", name: "Testimonial" },
    { id: "#blog", name: "Blog" },
    { id: "contact", name: "Contact" },
  ];

  const socialMedias = [
    { name: <FaFacebookF />, link: "#" },
    { name: <FaInstagram />, link: "#" },
    { name: <FaTwitter />, link: "#" },
    { name: <FaYoutube />, link: "#" },
  ];

  const handleIdClick = (navId) => {
    console.log(navId);
    navigator(`/${navId}`);
    if (navId === "#")
      window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='bg-blue-300 py-5 px-5 md:px-28 w-screen'>
        <footer className="bg-white rounded-lg shadow">
          <div className="w-full max-w-screen-xl mx-auto py-2 px-14 ">
            <div className="md:flex md:justify-between md:items-center">
              <div className="flex items-center justify-center mb-2 sm:mb-0 space-x-3 ">
                <img src={Logo} className="h-12 w-12" alt="Flowbite Logo" />
                <span className="self-center text-[1.6rem] font-semibold whitespace-nowrap text-blue-700">Vrisharya Technology</span>
              </div>
              <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                {navList.map((item, idx) => (
                  <li key={idx}>
                    <ScrollLink activeClass="active" onClick={() => handleIdClick(item.id)}
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                      className="hover:underline me-4 md:me-6 hover:text-blue-500 transition-all duration-300 cursor-pointer">
                      {item.name}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </div>
            <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700" />
            <div className='md:flex md:justify-between md:items-center'>
              <span className="block text-sm text-gray-500 sm:text-center">© 2023 Vrisharya Technology™. All Rights Reserved.</span>
              <div className='text-blue-500 flex end-[calc(1rem)] gap-4 text-lg px-8'>
                {socialMedias.map((item, idx) => (
                  <NavLink key={idx} to={item.link}>
                    <div className='w-10 h-10 flex items-center justify-center rounded-full hover:bg-blue-500 hover:text-white transition-all duration-500 cursor-pointer'>
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer