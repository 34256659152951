// fetchUrl.js
const fetchUrl = async () => {
    try {
        const response = await fetch('https://vrisharyatechnologies.com/test/api/GetUrl.php');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.url; // Return the fetched URL
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return null;
    }
};

export default fetchUrl;
