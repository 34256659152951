import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { finalUrl } from '../../constants.jsx';
import { Input, Textarea } from "@material-tailwind/react"
import RegisterBG from '../assets/Register-Background.png';

function Contact() {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Subject: '',
    Message: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const clearFields = () => {
    setFormData({
      Name: "",
      Email: "",
      Phone: "",
      Subject: "",
      Message: "",
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = finalUrl + '/contactus';
      // console.log(url);
      // Send the data to your new API endpoint (replace 'YOUR_API_ENDPOINT' with the actual endpoint)
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type for FormData
        },
      });

      // Handle the response from the server
      setMessage(response.data);

      clearFields();
    } catch (error) {
      console.error(error);
      setMessage('Error submitting the form.');
    }
  };

  useEffect(() => {
    let pageVerticalPosition = localStorage.getItem('contactTop') || 0;
    window.scrollTo(0, pageVerticalPosition);
  });


  return (
    <>
      <div id='contactTop' className="min-h-screen py-10" style={{ backgroundImage: "linear-gradient(115deg, #9F7AEA, #FEE2FE)" }}>
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row md:w-10/12 lg:w-8/12 bg-white rounded-xl mx-4 md:mx-auto shadow-lg overflow-hidden">
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${RegisterBG})` }}>
              <h1 className='text-xl text-slate-100'>Welcome To</h1>
              <h1 className="text-blue-500 text-3xl mb-3">Vrisharya Technology</h1>
              <div>
                <p className="text-white text-justify">
                  Empowering Minds, Shaping Futures: Where Innovation Meets Education. Join us on the journey to revolutionize learning, unlocking limitless possibilities in the world of EdTech excellence.</p>
              </div>
            </div>
            <div className="md:w-1/2 py-10 px-9 md:py-16 md:px-14">
              <h2 className="text-3xl mb-4">Contact Us</h2>
              <p className="mb-4">
                Reach out for inquiries, support or collaboration.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="">
                  <Input type='text' name='Name' value={formData.Name} onChange={handleChange} required label='Name' color='blue' />
                </div>
                <div className="mt-5">
                  <Input type="email" name="Email" value={formData.Email} onChange={handleChange} required label='Email' color='blue' />
                </div>
                <div className="mt-5">
                  <Input type="text" name="Phone" value={formData.Phone} onChange={handleChange} label='Phone' color='blue' />
                </div>
                <div className="mt-5">
                  <Input type="text" name="Subject" value={formData.Subject} onChange={handleChange} required label='Subject' color='blue' />
                </div>
                <div className="mt-5">
                  <Textarea name="Message" rows="4" value={formData.Message} onChange={handleChange} required label='Message' color='blue' />
                </div>
                <div className="mt-5">
                  <button className="w-full bg-purple-500 py-3 text-center text-white rounded-lg">Submit</button>
                </div>
              </form>

              <p className="message">{message}</p>
            </div>
          </div>
        </div>
      </div>

      {/* <ReadContactUs /> */}
    </>

  );
}

export default Contact;