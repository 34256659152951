import React from 'react'
import { FaFacebookF, FaFingerprint, FaInstagram, FaRetweet, FaTwitter, FaYoutube } from 'react-icons/fa'
import { GrTechnology } from "react-icons/gr"
import { NavLink } from 'react-router-dom'
import { mainImg } from '../assets/index'

const Hero = () => {
  const socialMedias = [
    { name: <FaFacebookF />, link: "#" },
    { name: <FaInstagram />, link: "#" },
    { name: <FaTwitter />, link: "#" },
    { name: <FaYoutube />, link: "#" },
  ]
  return (
    <>
      <section id="home" className='pr-4 pl-4 pt-5 bg-slate-100'>
        <div className='z-40 text-blue-500 absolute flex end-[calc(1rem)] gap-4 text-lg pt-6 px-8 -mr-2 md:mr-0'>
          {socialMedias.map((item, idx) => (
            <NavLink key={idx} to={item.link}>
              <div className='w-10 h-10 flex items-center justify-center bg-white rounded-full hover:bg-blue-500 hover:text-white transition-all duration-500 cursor-pointer'>
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <div className="relative pb-32 flex content-center items-center justify-center h-[38rem]">
          <div className="absolute top-0 w-full h-[39rem] bg-center bg-cover" style={{ backgroundImage: `url(${mainImg})`, borderRadius: "10px" }} >
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black rounded-lg"></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto md:text-center">
                <div className="md:pr-10 mt-10 md:-mt-14">
                  <h1 className="text-white font-semibold text-5xl pb-4 text-center">
                    Vrisharya Technology Private Limited <br />
                  </h1>
                  <p className="mt-5 text-lg text-gray-300 md:text-center text-justify">
                    An emerging EdTech startup is revolutionizing education with its innovative online
                    learning platform. Seamlessly blending interactive courses with offline resources,
                    it caters to diverse learning styles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="bg-slate-100 -mt-44 md:-mt-56">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <GrTechnology className='h-5 w-5' />
                    </div>
                    <h6 className="text-xl font-semibold">Technological Innovation</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    We prioritize democratizing education through open-source tools, fostering inclusive learning environments & commitment to free innovation empowers educators and learners worldwide.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <FaRetweet className='h-5 w-5' />
                    </div>
                    <h6 className="text-xl font-semibold">
                      Free Revisions
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Enjoy our free complimentary revision feature, ensuring your academic satisfaction with our courses and materials. Your success is our priority.
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <FaFingerprint className='h-5 w-5' />
                    </div>
                    <h6 className="text-xl font-semibold">Verified Company</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      The company has undergone rigorous authentication procedures, affirming its legitimacy, trustworthiness, and adherence to industry standards and regulations, ensuring reliability within its specific sector.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Hero