import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar, Footer, Contact, Login, Registration } from "./components/common/index";
import { Home } from "./components/index";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/contact' element={<Contact />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/user" element={<Registration />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;