import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { RiMenuAddLine, RiCloseLine } from "react-icons/ri"
import { FaHome } from "react-icons/fa"
import { MdPersonSearch, MdConnectWithoutContact } from "react-icons/md"
import { GrTechnology } from "react-icons/gr";
import { Logo } from '../assets/index';
import { Link as ScrollLink } from "react-scroll"

const Navbar = () => {

  const navItems = [
    { name: "Home", id: "#", link: "home", to: "/", navIcon: <FaHome /> },
    { name: "About", id: "#about", link: "about", to: "/", navIcon: <MdPersonSearch /> },
    { name: "Services", id: "#services", link: "services", to: "/", navIcon: <GrTechnology /> },
    { name: "Contact Us", id: "#contact_us", link: "contact_us", to: "/", navIcon: <MdConnectWithoutContact /> },
  ]

  const [isMobile, setIsMobile] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  // const [moreMenu, setMoreMenu] = useState(false);
  const navigator = useNavigate();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 500;
      setIsScrolled(scrollPosition > scrollThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleIdClick = (navId) => {
    setSideMenu(false);
    // setMoreMenu(false);
    navigator(`/${navId}`);
  };

  useEffect(() => {
    const url = window.location.href;
    const hashIndex = url.indexOf('#');

    if (hashIndex !== -1) {
      const id = url.substring(hashIndex + 1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
        setSideMenu(false);
      }
    });
  }, []);

  return (
    <>
      <div className={`${isMobile ? 'hidden' : "relative px-4 py-3 w-full h-auto z-50"}`}>
        <nav className={`${isScrolled ? "fixed top-4 left-4 right-4 px-4" : "relative"} py-4 px-4 rounded-2xl bg-gray-50 shadow-xl flex justify-between items-center`}>
          <div className='px-12 flex items-center'>
            <ScrollLink activeClass='active' onClick={() => handleIdClick("#")} className='cursor-pointer' to="home" spy={true} smooth={true} offset={-70} duration={1000}><img className="h-12 w-auto mr-5" src={Logo} alt="Vrisharya Technology" /></ScrollLink>
            <ScrollLink activeClass='active' onClick={() => handleIdClick("#")} className='cursor-pointer' to="home" spy={true} smooth={true} offset={-70} duration={1000}><h2 className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</h2></ScrollLink>
          </div>
          <div>
            <ul className='flex justify-center gap-1'>
              {navItems.map((item, idx) => (
                <li className='px-2' key={idx}>
                  <ScrollLink activeClass="active" onClick={() => handleIdClick(item.id)} to={item.link} spy={true} smooth={true} offset={-70} duration={1000}
                    className='text-[0.92rem] font-semibold text-blue-800 hover:text-blue-500 duration-300 cursor-pointer border-b-2 border-transparent hover:border-blue-900'>
                    {item.name}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Extra Menu and login*/}
        </nav>
      </div>

      {/* for mobile devices */}
      <div className=''>
        <nav className={`${isMobile ? 'relative px-4 py-3 w-full h-auto z-50 flex justify-between items-center' : "hidden"}`}>
          <div className='flex items-center h-10'>
            {/* <img src={Logo} alt="logo" className='h-12 w-auto mr-4' /> */}
            <h2 className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</h2>
          </div>
          <RiMenuAddLine className={`${!sideMenu ? "text-2xl font-bold text-blue-700 cursor-pointer" : "hidden"}`} onClick={() => setSideMenu(true)} />
        </nav>
        <div className={`${sideMenu ? "fixed h-full w-screen bg-black/50 backdrop-blur-sm top-0 lg:left-0 z-50" : "hidden"}`}>
          <section className='text-black bg-white flex-col absolute right-0 top-0 h-screen p-8 gap-2 z-50 w-1/2 flex rounded-tl-2xl'>
            <div className='flex items-center justify-between'>
              <img src={Logo} alt="logo" className='h-12 w-auto cursor-pointer' />
              <RiCloseLine className="text-3xl text-blue-700 cursor-pointer" onClick={() => setSideMenu(false)} />
            </div>
            <hr className='bg-blue-300 h-0.5' />
            {navItems.map((item, idx) => (
              <ScrollLink key={idx} onClick={() => handleIdClick(item.id)} to={item.link} spy={true} smooth={true} offset={-70} duration={1000}
                className='px-2 py-2 flex items-center gap-2 text-blue-400 font-semibold hover:bg-blue-100 hover:text-white hover:rounded-md cursor-pointer'>
                {item.navIcon}{item.name}
              </ScrollLink>
            ))}
            {/* for mobile extra menu */}
          </section>
        </div>
      </div>
    </>
  )
}

export default Navbar
