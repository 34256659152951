import React, { useState } from 'react'
import { image7 } from '../assets/index'
import { PiCertificateBold, PiCodeBold } from 'react-icons/pi'
import { FaChalkboardTeacher } from 'react-icons/fa'

const About = () => {

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const aboutItems = [
    {
      img: <FaChalkboardTeacher />,
      title: "Online Courses",
      description: "Join us and elevate your educational journey with top tier online course services designed to meet your unique needs.",
    },
    {
      img: <PiCertificateBold />,
      title: "Get Certified",
      description: "Join us to earn a certification that validates your expertise and sets you on a path to success in the educational technology domain.",
    },
    {
      img: <PiCodeBold />,
      title: "Code with Us",
      description: "Join us to earn a certification that validates your expertise and sets you on a path to success in the educational technology domain.",
    },
  ];

  return (
    <>
      <div id="about" className='bg-white px-4 h-auto mt-10 mb-28'>
        <div className='flex justify-center py-10'>
          <h1 className='font-bold text-[2.5rem] text-blue-500'>About Us</h1>
        </div>
        <div className='md:flex w-full justify-center items-center rounded-lg bg-[conic-gradient(var(--tw-gradient-stops))] from-blue-100 via-blue-300 to-blue-500'>
          {/* Left Side */}
          <div className='md:w-1/2 p-3'>
            <img src={image7} alt="left_image" className='h-[25rem] md:h-[45rem] w-full rounded-lg object-cover' />
          </div>

          {/* Right Side */}
          <div className='md:w-1/2 px-[1rem] md:px-[4rem]'>
            <div className='-mt-80 md:mt-0 flex-col justify-center items-center text-center md:text-start'>
              <h2 className="text-[1.5rem] font-semibold text-white md:text-blue-800 mt-6">LEARN ANYTHING</h2>
              <p className='text-4xl font-bold text-white md:text-blue-700 pt-4 pb-5'>Benefits About Online Learning Experts</p>
            </div>
            <div className='mt-24 md:mt-0'>
              {aboutItems.map((item, idx) => (
                <div className='py-4 w-auto cursor-pointer' key={idx}>
                  <div
                    className="flex justify-center items-center bg-white px-4 py-2 gap-5 h-fit rounded-md shadow-lg transition-all duration-500 hover:bg-blue-400 hover:text-white"
                    onMouseEnter={() => setHoveredIndex(idx)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                  >
                    <div className={`text-6xl ${hoveredIndex === idx ? " hover:text-white" : 'text-orange-400'}`}>{item.img}</div>
                    <div>
                      <h1 className='text-2xl font-semibold'>{item.title}</h1>
                      <p className='text-lg'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About